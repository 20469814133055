import axios from 'axios';
const baseurl = process.env.REACT_APP_API_URL;

const api_instance = axios.create({
  baseURL: baseurl,
  withCredentials: true,
});


export const refreshToken = async () => {
  try {
    const res = await api_instance.get('/auth/refresh')
    if (res) {
      console.log(res)
      return res
    }

  } catch (error) {
    console.error('Error refreshing token:', error);
  }
  return null;
};



api_instance.interceptors.request.use(
  async (config) => {
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);


api_instance.interceptors.response.use(
  (response) => {
    return response;
  },
  async (error) => {
    const originalRequest = error.config;
    if (error.response?.status === 401 && !originalRequest._retry) {
      originalRequest._retry = true;
      const res = await refreshToken();
      if (res && res.data) {
        return api_instance(originalRequest);
      }
    }
    return Promise.reject(error);
  }
);

export default api_instance;
