import {useEffect, useState} from 'react'
import {useNavigate} from 'react-router-dom'
import axios from '../../Helpers/Api_instance'
import {KTSVG} from '../../Ihouse/helpers'
import {toast} from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
export interface IAppProps {}

export default function App({id, getBookings}) {
  const [booking, setBooking] = useState<any>(null)

  const getBookingById = async () => {
    try {
      const res = await axios.get('/bookings/' + id)
      if (res && res.data) {
        setBooking(res.data.data.booking)
      }
    } catch (error: any) {
      console.log(error.message)
    }
  }

  const handleAccept = async () => {
    try {
      const res = await axios.put(`/bookings/${id}`, {status: 1})
      if (res && res.data) {
        toast.success('Status updated successfully')
        getBookings()
        getBookingById()
      }
    } catch (error: any) {
      console.log(error.message)
      toast.error('Unable to update Status')
    }
  }

  const handleReject = async () => {
    try {
      const res = await axios.put(`/bookings/${id}`, {status: 2})
      if (res && res.data) {
        toast.success('Status updated successfully')
        getBookings()
        getBookingById()
      }
    } catch (error: any) {
      console.log(error.message)
      toast.error('Unable to update Status')
    }
  }

  const handleAttended = async () => {
    try {
      let atten = booking && booking.attended === true ? false : true
      const res = await axios.put(`/bookings/attended/${id}`, {attended: atten})
      if (res && res.data) {
        getBookings()
        getBookingById()
        if (atten) {
          toast.success('Booking Marked as Attended successfully')
        } else {
          toast.success('Booking Marked as Not Attended successfully')
        }
      }
    } catch (error: any) {
      console.log(error.message)
      toast.error('Unable to update Status')
    }
  }

  useEffect(() => {
    if (id !== '') {
      getBookingById()
    }
  }, [id])
  return (
    <div>
      <div className='modal fade' tabIndex={-1} id='kt_modal_10'>
        <div className='modal-dialog'>
          <div className='modal-content'>
            <div className='modal-header'>
              <h5 className='modal-title'>Booking Details</h5>
              <div
                className='btn btn-icon btn-sm btn-active-light-primary ms-2'
                data-bs-dismiss='modal'
                aria-label='Close'
              >
                <KTSVG path='media/icons/duotune/arrows/arr061.svg' />
              </div>
            </div>
            <div className='modal-body'>
              <div className='row mb-7'>
                <label className='col-lg-4 fw-bold text-muted'>Name :</label>

                <div className='col-lg-8'>
                  <span className='fw-bolder fs-6 text-dark'>
                    {booking && booking.user.first_name}
                  </span>
                </div>
              </div>

              <div className='row mb-7'>
                <label className='col-lg-4 fw-bold text-muted'>Email :</label>

                <div className='col-lg-8 fv-row text-=start'>
                  <span className='fw-bold fs-6'>{booking && booking.email}</span>
                </div>
              </div>

              <div className='row mb-7'>
                <label className='col-lg-4 fw-bold text-muted'>Phone No: </label>

                <div className='col-lg-8'>
                  <span className='fw-bold fs-6'>{booking && booking.user.phone}</span>
                </div>
              </div>

              <div className='row mb-7'>
                <label className='col-lg-4 fw-bold text-muted'>Date & Time :</label>

                <div className='col-lg-8'>
                  <a href='#' className='fs-6'>
                    {booking && booking.date} || {booking && booking.time}
                  </a>
                </div>
              </div>

              <div className='row mb-7'>
                <label className='col-lg-4 fw-bold text-muted'>
                  Guestes :
                  <i
                    className='fas fa-exclamation-circle ms-1 fs-7'
                    data-bs-toggle='tooltip'
                    title='Country of origination'
                  ></i>
                </label>

                <div className='col-lg-8'>
                  <span className='fw-bolder fs-6 text-dark'>{booking && booking.guests}</span>
                </div>
              </div>
              <div className='row mb-7'>
                <label className='col-lg-4 fw-bold text-muted'>Message :</label>

                <div className='col-lg-8'>
                  <span className='fs-6 text-dark'>{booking && booking.note}</span>
                </div>
              </div>

              <div className='row mb-7'>
                <label className='col-lg-4 fw-bold text-muted'>Status :</label>

                <div className='col-lg-5'>
                  <span
                    className={
                      booking && booking.status == 1
                        ? 'badge badge-light-success fs-7 fw-bold'
                        : booking && booking.status == 0
                        ? 'badge badge-light-warning fs-7 fw-bold'
                        : 'badge badge-light-danger fs-7 fw-bold'
                    }
                  >
                    {(booking && booking.status == 0 && 'Pending') ||
                      (booking && booking.status == 1 && 'Accepted') ||
                      (booking && booking.status == 2 && 'Rejected')}
                  </span>
                </div>
              </div>
              <div className='row mb-10'>
                <label className='col-lg-4 fw-bold text-muted'>Attended :</label>

                <div className='col-lg-8'>
                  <span>
                    <div className='mb-10'>
                      <div
                        className={
                          booking && booking.attended === true
                            ? 'form-check form-switch form-check-custom form-check-success'
                            : 'form-check form-switch form-check-custom form-check-danger'
                        }
                      >
                        <input
                          className='form-check-input'
                          type='checkbox'
                          value=''
                          checked={booking && booking.attended}
                          onChange={handleAttended}
                          id='flexSwitchDefault'
                        />
                        <label className='form-check-label' htmlFor='flexSwitchDefault'>
                          <span
                            className={
                              booking && booking.attended
                                ? 'badge badge-light-success fs-7 fw-bold'
                                : 'badge badge-light-danger fs-7 fw-bold'
                            }
                          >
                            {booking && booking.attended == true ? 'Attended' : 'Not Attended'}
                          </span>
                        </label>
                      </div>
                    </div>
                  </span>
                </div>
              </div>
            </div>
            {booking && booking.status == 0 && (
              <div className='modal-footer'>
                <a
                  className='btn btn-light-danger me-3'
                  data-bs-dismiss='modal'
                  onClick={() => {
                    handleReject()
                  }}
                >
                  Reject
                </a>
                <button
                  type='submit'
                  className='btn btn-light-success'
                  data-bs-dismiss='modal'
                  onClick={() => {
                    handleAccept()
                  }}
                >
                  Accept
                </button>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  )
}
