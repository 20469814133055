import {useEffect} from 'react'
import {Outlet, Link} from 'react-router-dom'
import {toAbsoluteUrl} from '../../../Ihouse/helpers'
import {LazyLoadImage} from 'react-lazy-load-image-component'
import 'react-lazy-load-image-component/src/effects/opacity.css'

const AuthLayout = () => {
  useEffect(() => {
    const root = document.getElementById('root')
    if (root) {
      root.style.height = '100%'
    }
    return () => {
      if (root) {
        root.style.height = 'auto'
      }
    }
  }, [])

  return (
    <div className='d-flex flex-column flex-lg-row flex-column-fluid h-100'>
      {/* Left Section (Background) */}
      <div
        className='d-flex flex-lg-row-fluid w-lg-50 bgi-size-cover bgi-position-center order-1 order-lg-1'
        style={{backgroundImage: `url(${toAbsoluteUrl('/media/auth/bg-dark.jpg')})`}}
      >
        {/* Content */}
        <div className='d-flex flex-column flex-center py-15 px-5 px-md-15 w-100'>
          {/* Logo */}
          <Link to='/' className='mb-12'>
            <LazyLoadImage
              alt='Logo'
              effect='blur'
              src={toAbsoluteUrl('/media/logos/Logo.png')}
              className='h-100px'
            />
          </Link>

          {/* Image */}
          <img
            className='mx-auto w-275px w-md-50 w-xl-400px mb-10 mb-lg-20'
            src={toAbsoluteUrl('/media/auth/12.png')}
            alt=''
          />

          {/* Title */}
          <h1 className='text-white fs-2qx fw-bolder text-center mb-7'>
            Taste the magic of India on your plate
          </h1>

          {/* Text */}
          <div className='text-white fs-base text-center'>
            Embark on a culinary journey,{' '}
            <a href='#' className='opacity-75-hover text-warning fw-bold me-1'>
              at our Indian restaurant
            </a>
            in the UK. Immerse yourself in authentic flavors, where tradition and innovation come
            together to{' '}
            <a href='#' className='opacity-75-hover text-warning fw-bold me-1'>
              create unforgettable
            </a>
            dining experiences."
          </div>
        </div>
      </div>

      {/* Right Section (Login Form) */}
      <div className='d-flex flex-column flex-lg-row-fluid w-lg-50 p-10 order-2 order-lg-2'>
        {/* Form Wrapper */}
        <div className='d-flex flex-center flex-column flex-lg-row-fluid'>
          {/* Form */}
          <div className='w-lg-500px p-10'>
            <Outlet />
          </div>
        </div>
        {/* Footer Links */}
        <div className='d-flex flex-center flex-wrap px-5'>
          <div className='d-flex fw-semibold text-primary fs-base'>
            <div className='text-gray-500 fw-semibold fs-5'>Designed By: </div>
            {/* Designer Logo */}
            <Link to='/' className='mb-12 ms-3'>
              <LazyLoadImage
                effect='blur'
                alt='Logo'
                src={toAbsoluteUrl('/media/logos/Spack-logo.png')}
                className='h-20px'
              />
            </Link>
          </div>
        </div>
      </div>
    </div>
  )
}

export {AuthLayout}
